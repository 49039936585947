<template>
  <land-section
    space="80"
  >
    <land-section-heading
      v-if="header.title"
      :title="header.title"
    >
      {{ header.subtitle }}
    </land-section-heading>
    <v-container
      style="max-width: 1200px;"
    >
      <v-row
        no-gutters
        justify="center"
      >
        <v-col cols="12">
          <land-gallery
            :items="items"
            :ratio="ratio"
            back-color="grey lighten-5"
            @click="onActivity"
          />
        </v-col>
      </v-row>
    </v-container>
    <div
      v-if="button.text"
      class="d-flex flex-column align-center"
    >
      <v-btn
        depressed
        :color="button.backColor || 'primary'"
        :class="[button.foreColor ? `${button.foreColor}--text` : 'white--text', 'mt-5']"
        width="120"
        @click="onMore"
      >
        {{ button.text }}
      </v-btn>
    </div>
  </land-section>
</template>

<script>
  import api from '@/api/co.api'

  import mixPreset from '@/pages/mixins/mix.preset'
  const parts = mixPreset.Parts
  const names = mixPreset.Names
  const part = parts[names.ACTIVITY]

  export default {
    name: 'CardSchemes',
    props: {
      header: {
        type: Object,
        default: () => {
          return {
            title: '解决方案',
            subtitle: ''
          }
        }
      },
      items: {
        type: Array,
        default: () => {
          return []
        }
      },
      button: {
        type: Object,
        default: () => {
          return {
            text: '更多方案'
          }
        }
      },
    },
    data () {
      return {
        type: part.type,
        ratio: part.ratio,
      }
    },
    created () {
    },
    methods: {
      onActivity (ev) {
        const item = ev.item
        const params = {
          contentId: item.contentId,
        }
        api.page.navigate(this, 'schemeDetail', params)
      },
      onMore () {
        api.page.navigate(this, 'scheme')
      },
    }
  }
</script>
