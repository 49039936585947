
// import api from '@/api/co.api'
import web from '@/api/web/co.web'

const ProcessingStates = [
  web.comm.ActivityStates.PUBLISH,
  web.comm.ActivityStates.DOING
]

const Names = {
  IMAGE: 'image',
  VIDEO: 'video',
  POUND: 'pound',
  ARTICLE: 'article',
  ACTIVITY: 'activity'
}

const CatImage = {
  title: '图片',
  name: Names.IMAGE,
  type: web.comm.ContentTypes.IMAGE,
  state: web.comm.States.PUBLISH,
  forwardedFlag: 'no',
  ratio: 1.45,
  margin: 'ma-4',
  tooled: true,
  forwarded: true,
  liked: true,
  albumed: true,
  navigated: false,
  params: null,
  items: [],
  empty: '当前没有发表图片',
  latest: [],
  pagination: {
    current: 1,
    length: 0,
    totalVisible: 7
  },
}

const CatVideo = {
  title: '视频',
  name: Names.VIDEO,
  type: web.comm.ContentTypes.VIDEO,
  state: web.comm.States.PUBLISH,
  forwardedFlag: 'no',
  ratio: 1.64,
  margin: 'ma-4',
  tooled: true,
  forwarded: true,
  liked: true,
  albumed: false,
  navigated: false,
  params: null,
  items: [],
  empty: '当前没有发表视频',
  pagination: {
    current: 1,
    length: 0,
    totalVisible: 7
  },
}

const CatPound = {
  title: '话题',
  name: Names.POUND,
  type: web.comm.ContentTypes.POUND,
  state: web.comm.States.PUBLISH,
  forwardedFlag: 'no',
  ratio: 1.45,
  margin: 'ma-4',
  tooled: true,
  forwarded: true,
  liked: true,
  albumed: true,
  navigated: false,
  params: null,
  items: [],
  empty: '当前没有发表话题',
  latest: [],
  pagination: {
    current: 1,
    length: 0,
    totalVisible: 7
  },
}

const CatArticle = {
  title: '文章',
  name: Names.ARTICLE,
  type: web.comm.ContentTypes.ARTICLE,
  state: web.comm.States.PUBLISH,
  forwardedFlag: 'no',
  ratio: 1.85,
  margin: 'ma-4',
  tooled: true,
  forwarded: true,
  liked: true,
  albumed: false,
  navigated: false,
  params: null,
  items: [],
  empty: '当前没有发表文章',
  pagination: {
    current: 1,
    length: 0,
    totalVisible: 7
  },
}

const CatActivity = {
  title: '活动',
  name: Names.ACTIVITY,
  type: '',
  state: ProcessingStates.join('|'),
  forwardedFlag: '',
  ratio: 1.85,
  margin: 'ma-4',
  tooled: false,
  forwarded: true,
  liked: true,
  albumed: true,
  navigated: false,
  params: null,
  items: [],
  empty: '当前没有发布活动',
  pagination: {
    current: 1,
    length: 0,
    totalVisible: 7
  },
}

const PlayerConfig = {
  playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
  autoplay: false, // 如果为true,浏览器准备好时开始回放。
  muted: false, // 默认情况下将会消除任何音频。
  loop: false, // 是否视频一结束就重新开始。
  preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
  language: 'zh-CN',
  aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
  fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
  sources: [{
    type: 'video/mp4', // 类型
    src: '' // url地址
  }],
  poster: '', // 封面地址
  notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
  controlBar: {
    timeDivider: true, // 当前时间和持续时间的分隔符
    durationDisplay: true, // 显示持续时间
    remainingTimeDisplay: false, // 是否显示剩余时间功能
    fullscreenToggle: true // 是否显示全屏按钮
  }
}

const mixPreset = {
  PlayerConfig,
  Names,
  Parts: {
    [Names.IMAGE]: CatImage,
    [Names.VIDEO]: CatVideo,
    [Names.POUND]: CatPound,
    [Names.ARTICLE]: CatArticle,
    [Names.ACTIVITY]: CatActivity
  }
}

export default mixPreset
